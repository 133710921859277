.loading {
  display: none;
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  background-color: rgba(255, 255, 255, 0.1);
  animation: startLoading 1s;
  animation-fill-mode: both;
}

.loading.start {
  display: flex;
}
.loading.stop {
  display: none;
}
.loading.start figure img {
  position: absolute;
  width: 30vmin;
  max-width: 300px;
  height: 30vmin;
  max-height: 300px;
  align-self: center;
  animation-fill-mode: both;
}
.loading.start figure img.done-icon {
  display: none;
  animation: doneLoading 1.5s;
  animation-fill-mode: both;
}
.loading.start.done figure img.done-icon {
  display: flex;
}
.loading.start.done figure img.logo-icon {
  animation: logoOut 0.5s;
  animation-fill-mode: both;
}
.loading figure {
  width: 30vmin;
  max-width: 300px;
  height: 30vmin;
  max-height: 300px;
  animation: loading 0.7s infinite alternate-reverse;
  justify-content: center;
  align-items: center;
}

@keyframes loading {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes startLoading {
  from {
    backdrop-filter: blur(0);
    background-color: rgba(255, 255, 255, 0);
  }

  to {
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.1);
  }
}

@keyframes doneLoading {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  40% {
    opacity: 1;
    /* transform: scale(1.1); */
  }
  60% {
    opacity: 1;
    /* transform: scale(1.1); */
  }
  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}
@keyframes logoOut {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.background-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-image: url('../../../assets/images/login/bgs/bg_login.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}

@media (max-width: 768px) {
  .background-login {
    background-image: url('../../../assets/images/login/bgs/bg_login_mobile.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
  }
}

.screen-loading {
  position: absolute;
  justify-content: center;
  align-items: center;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  backdrop-filter: blur(0);
  background-color: rgba(255, 255, 255, 0);
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.screen-loading.start {
  backdrop-filter: blur(3px);
  background-color: rgba(255, 255, 255, 0.1);
  opacity: 1;
}

.screen-loading .icon {
  opacity: 0;
  transform: scale(0.7);
  transition: all 0.4s ease-in-out;
}
.screen-loading.start .icon {
  opacity: 1;
  transform: scale(1);
}

.fc th {
  color: #422c76;
}

.fc-next-button.fc-button.fc-button-primary:hover,
.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-today-button.fc-button.fc-button-primary:hover {
  background-color: #f2e9ffab !important;
  transition-duration: 200ms;
}

.fc-scrollgrid-sync-inner {
  height: 100%;
}

.fc .fc-daygrid-day-frame {
  min-height: 160px;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover:hover {
  background: transparent;
}
.fc-daygrid-event-harness {
  width: 100%;
  display: flex;
  justify-content: center;
}

.fc-daygrid-day-events {
  margin: 0px 10px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fc-scroller {
  overflow: auto !important;
}

.fc .fc-highlight {
  background-color: #f2e9ffab;
}

.fc-h-event {
  background-color: transparent !important;
  border: none !important;
}

.fc-popover-body {
  max-height: 160px;
  overflow-y: auto;
}

.fc-toolbar-chunk {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #422c76;
}

.fc-toolbar-title {
  margin: 5px 0px;
  font-size: 16px !important;
  min-width: 300px;
  font-weight: bold;
  color: #422c76;
  text-align: center;
}

.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0px;
}

.fc .fc-button-primary {
  background-color: transparent !important;
  border: none;
}

.fc .fc-col-header-cell-cushion {
  text-transform: lowercase;
}

.fc .fc-col-header-cell-cushion:first-letter {
  text-transform: uppercase;
}

.fc .fc-button:not(:disabled) {
  color: #422c76 !important;
  opacity: 1;
  font-weight: bold;
}

.fc .fc-button-primary:disabled {
  display: none;
}

.fc .fc-button .fc-icon {
  color: #422c76;
}

.fc .fc-daygrid-more-link {
  color: #422c76;
  text-decoration: underline;
  font-weight: bold;
}

.fc-daygrid-day-bottom {
  margin-top: 10px !important;
}

.fc .fc-popover-close {
  color: red;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f2e9ffab;
}

@import url('https://fonts.googleapis.com/css2?family=Hanken+Grotesk:wght@400;700&display=swap');

html {
  scroll-behavior: smooth;
}

/* Isso irá afetar a barra de rolagem em todo o elemento do corpo */
*::-webkit-scrollbar {
  border-radius: 6px;
  width: 5px; /* largura da barra de rolagem */
  transition: 0.2s;
}

*::-webkit-scrollbar-track {
  border-radius: 6px;
  background-color: #f9f9f9; /* cor de fundo da trilha da barra de rolagem */
  transition: 0.2s;
}

*::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #cecece; /* cor de fundo do controle deslizante da barra de rolagem */
  transition: 0.2s;
}
* {
  scrollbar-color: #333 #f9f9f9; /* cor do polegar e trilha da barra de rolagem, respectivamente */
}

/* Cor de fundo do controle deslizante da barra de rolagem quando o mouse está sobre ele */
*::-webkit-scrollbar-thumb:hover {
  background-color: #b3b3b3;
  transition: 0.2s;
}

/* Cor de fundo do controle deslizante da barra de rolagem quando está sendo clicado ou arrastado */
*::-webkit-scrollbar-thumb:active {
  background-color: #999999;
  transition: 0.2s;
}
.chakra-modal__content-container {
  background: rgba(0, 0, 0, 0.6);
}
/* .chakra-modal__content-container * {
  animation-duration: 20s;
} */
/* .chakra-modal__content {
  animation: upFade 0.5s cubic-bezier(0.51, 0.04, 0.22, 0.99) !important;
} */
p.chakra-text:not(.blink) {
  animation: rlFade 0.9s cubic-bezier(0.51, 0.04, 0.22, 0.99);
}
header p.chakra-text
/* ,.chakra-tabs__tab-panels p.chakra-text  */ {
  animation: downFade 0.2s cubic-bezier(0.51, 0.04, 0.22, 0.99);
}

/* .chakra-tabs__tab-panels > div {
  animation: fade 0.5s ease;
} */
* {
  font-family: 'Hanken Grotesk';
}
*:focus {
  outline: none;
}

li {
  list-style: none;
}

.gray-box {
  padding: 10px 20px;
  background: #f2f2f2;
  border: 1px solid #6c48c24d;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  list-style: none;
  width: 100%;
  font-weight: 500;
  color: #0f0a1d;
  opacity: 0;
  animation: upFade 0.5s cubic-bezier(0, 0, 0.33, 0.94) both;
  animation-delay: var(--delay);
}
.gray-box li:not(:last-child) {
  border-bottom: 1px solid #70707022;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
p.chakra-text.up-anim,
.up-anim {
  opacity: 0;
  animation: upFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both;
  animation-delay: var(--delay);
}
p.chakra-text.rl-anim,
.rl-anim {
  opacity: 0;
  animation: rlFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both;
  animation-delay: var(--delay);
}
p.chakra-text.rl05-anim,
.rl05-anim {
  animation: rlFade05 0.7s cubic-bezier(0, 0, 0.33, 0.94) both;
}
p.chakra-text.down-anim,
.down-anim {
  opacity: 0;
  animation: downFade 0.7s cubic-bezier(0, 0, 0.33, 0.94) both;
  animation-delay: var(--delay);
}

.progress-anim {
  animation: progress 0.7s cubic-bezier(0, 0, 0.33, 0.94) both;
}

.timeline-step img {
  animation: fade-scale 0.7s cubic-bezier(0.51, -0.37, 0, 4.02);
  opacity: 0;
  animation-fill-mode: both;
  animation-delay: var(--delay);
}

.timeline-step div {
  transform-origin: left center;
  opacity: 0;
  animation: progress 0.5s ease;
  animation-fill-mode: both;
  animation-delay: var(--delay);
}
.timeline-step p {
  animation-delay: var(--delay);
}
.process-timeline * {
  transform-origin: left center;
  opacity: 0;
  animation: rlFade 0.5s ease;
  animation-fill-mode: both !important;
  animation-delay: var(--delay);
}
.process-timeline img {
  animation-name: scale;
}

.float-button-modal {
  right: 40px;
  bottom: 40px;
  position: fixed !important;
}
.float-action-button * {
  transition: 0.5s cubic-bezier(0.51, 0.04, 0.22, 0.99) !important;
}
.float-action-button {
  position: fixed;
  bottom: 60px;
  right: 40px;
  z-index: 1200;

  display: flex;
  flex-flow: column-reverse;
}
.float-action-button > button {
  border-radius: 50px;
  padding: 10px;
  width: 50px;
  height: 50px;
  overflow: hidden;
  background-color: #422c76;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.float-action-button:hover > button {
  background-color: #8067dc;
}
.float-action-button > svg {
  transform: rotate(0deg);
}
.float-action-button:hover > button svg {
  transform: rotate(-90deg);
}
.float-action-button > button p {
  overflow: hidden;
  white-space: nowrap;
  width: 0px;
}
.float-action-button button:active {
  transition: 0.1s !important;
  transform: scale(0.9);
}

.float-action-button .options {
  position: absolute;
  flex-flow: column;
  bottom: 70px;
  right: 0px;
  /* backdrop-filter: blur(3px); */
  /* background-color: #e8eaedaa; */
  /* padding: 20px; */
  border-radius: 5px;
  /* box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15); */
  /* border: solid 1px #7070701a; */
}
.float-action-button .options li {
  display: flex;
  justify-content: flex-end;
  animation: flyOpen 0.6s cubic-bezier(0.53, -0.01, 0, 1.51) both !important;
  animation-delay: var(--delay) !important;
}
[aria-hidden='true'] .float-button-modal {
  right: 45px;
}

#bg_circle {
  fill: red !important;
}

.button {
  background: #6c48c2;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 15px;
  cursor: pointer;
  border-radius: 7px;
  padding: 16px;
  transition: 0.3s;
}

.table-default.min th,
.table-default.min td {
  padding: 20px 15px;
}
#react-select-4-listbox {
  z-index: 9999999 !important;
}

.table-default th,
.table-default td {
  border-bottom: none;
  color: #707070;
  font-size: 17px;
  padding: 10px;
}
.table-default thead,
.table-default tbody {
  border: 1px solid #7070703d;
}
.table-default thead th {
  padding: 10px;
  font: normal normal bold 16px/23px Hanken Grotesk;
  text-transform: unset;
  letter-spacing: 0.36px;
  color: #0f0a1d;
}
.table-default tbody tr {
  animation: downFade 0.2s cubic-bezier(0.51, 0.04, 0.22, 0.99);
  transition: 0.2s;
}
.table-default:not(.collapse) tbody tr:hover,
.table-default:not(.collapse) tbody tr:nth-child(2n + 1):hover {
  background-color: #e5e5e5;
}

.table-default:not(.collapse) tbody tr:nth-child(2n + 1) {
  background-color: #eee;
}

.table-default.collapse tbody tr:hover,
.table-default.collapse tbody tr:nth-child(2n + 1):hover {
  background-color: #e5e5e5;
}

.table-default.collapse tbody tr:nth-child(4n + 1) {
  background-color: #eee;
}

.table-reset tbody {
  border: 1px solid #7070703d !important;
}

.table-reset tbody tr,
.table-reset tbody td,
.table-reset tbody tr:hover {
  background: #fff !important;
  border-color: #7070703d !important;
}

th,
tr,
.table-default tbody tr,
.table-default tbody td {
  animation: downFade 0.4s cubic-bezier(0, 0, 0.33, 0.94);
  opacity: 0;
  animation-fill-mode: both;
  animation-delay: calc(var(--delay) / 3);
}

.button:hover {
  background-color: #8067dc;
}

.toggle-click {
  animation: toin 0.2s cubic-bezier(0.51, 0.04, 0.22, 0.99);
}
.toggle-click .star {
  animation: toin-star 0.4s cubic-bezier(0.51, 0.04, 0.22, 0.99);
}
.star {
  transition: all 0.3s;
  opacity: 0.5;
  filter: saturate(0);
}
.star:hover {
  transform: scale(1.2);
}
.star.active {
  filter: saturate(1);
  opacity: 1;
}
.mapboxgl-marker {
  transition: var(--transition) !important;
}

@keyframes flyOpen {
  0% {
    transform: translateY(20%) scaleX(0.7);
    opacity: 0;
  }
  to {
    transform: translateY(0%) scaleX(1);
    opacity: 1;
  }
}
@keyframes upFade {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }
  30% {
    transform: translateY(10%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes downFade {
  0% {
    transform: translateY(-10%);
    opacity: 0;
  }
  30% {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes rlFade {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes rlFade05 {
  0% {
    transform: translateX(-5%);
    opacity: 0;
  }
  30% {
    transform: translateX(-5%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 0.5;
  }
}
@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes scale {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
@keyframes fade-scale {
  0% {
    transform: scale(0.7);
    opacity: 0;
  }
  30% {
    transform: scale(0.7);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes progress {
  0% {
    transform: scaleX(0.1);
    opacity: 0;
  }
  30% {
    transform: scaleX(0.1);
    opacity: 0;
  }
  100% {
    transform: scaleX(1);
    opacity: 1;
  }
}
@keyframes toin {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes toin-star {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(0.8);
  }
  60% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.error-message-error {
  color: red;
  font-size: 0.75rem;
  margin-top: 1px;
}

/* .chakra-slide {
  width: 1200px !important;
  max-width: 60vw !important;
} */

.accordion-w-full .chakra-collapse {
  width: 100%;
}

.borderCalendar div {
  border-right: 1px solid #70707033;
  border-bottom: 1px solid #70707033;
}

.borderCalendar div div,
.borderCalendar:nth-child(n + 7) {
  border: 0;
}

.pac-container {
  z-index: 9999;
}

body .is-selected-type {
  background-color: #6c48c2 !important;
  color: #ffffff !important;
  transition: all 0.2 easy;
}

body .is-selected-type:hover {
  color: #6c48c2;
}

body .c-report-sidebar a[data-active='true'] {
  color: #a885f9;
}

body {
  font-family: 'Hanken Grotesk', sans-serif;
}

.custom-input-mask {
  width: 100%;
  height: 40px;
  font-size: var(--input-font-size);
  -webkit-padding-start: var(--input-padding);
  padding-inline-start: var(--input-padding);
  -webkit-padding-end: var(--input-padding);
  padding-inline-end: var(--input-padding);
  border-radius: var(--input-border-radius);
  min-width: 0px;
  outline: 2px solid transparent;
  outline-offset: 2px;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  transition-property: var(--chakra-transition-property-common);
  transition-duration: var(--chakra-transition-duration-normal);
  --input-font-size: var(--chakra-fontSizes-md);
  --input-padding: var(--chakra-space-4);
  --input-border-radius: var(--chakra-radii-md);
  --input-height: var(--chakra-sizes-10);
  border: var(--chakra-borders-1px);
  border-color: #70707033;
  font-size: 16px;
  padding-left: 25px;
  background: #f2f2f2;
}

.custom-input-mask::placeholder {
  color: #bab9be;
}

.custom-input-mask[disabled] {
  opacity: 0.4;
  cursor: not-allowed;
}

.Toastify__toast {
  z-index: 10001;
}

.fc th {
  color: #422c76;
}

.fc-next-button.fc-button.fc-button-primary:hover,
.fc-prev-button.fc-button.fc-button-primary:hover,
.fc-today-button.fc-button.fc-button-primary:hover {
  background-color: #f2e9ffab !important;
  transition-duration: 200ms;
}

.fc-timegrid-event-harness-inset.fc-timegrid-event,
.fc-timegrid-event.fc-event-mirror,
.fc-timegrid-more-link {
  box-shadow: none;
}

.fc-timegrid-event-harness.fc-timegrid-event-harness-inset {
  width: auto;
}

.fc-timegrid-event {
  padding: 0px;
}

.fc-media-screen .fc-timegrid-cols {
  z-index: 1;
}

.fc-direction-ltr .fc-timegrid-col-events {
  margin: 0px 20px;
}

.fc .fc-timegrid-now-indicator-line {
  border-width: 2px 0px 0px;
}

.fc-timegrid-divider fc-cell-shaded {
  display: block;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f2e9ffab;
}

.fc .fc-timegrid-col.fc-day-today {
  background-color: #f2e9ffab;
}

.fc-scroller {
  overflow: auto !important;
}

.fc-v-event {
  background-color: transparent !important;
  border: none !important;
}

.fc-daygrid-day-events {
  margin: 0px 20px;
}

.fc-popover-body {
  max-height: 160px;
  overflow-y: auto;
}

.fc-toolbar-chunk {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border-bottom: 1px solid #422c76;
}

.fc-toolbar-title {
  margin: 5px 0px;
  font-size: 16px !important;
  width: 300px;
  font-weight: bold;
  color: #422c76;
  text-align: center;
}

.fc-toolbar-title:first-letter {
  text-transform: uppercase;
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  margin-left: 0px;
}

.fc .fc-button-primary {
  background-color: transparent !important;
  border: none;
}

.fc .fc-col-header-cell-cushion {
  text-transform: lowercase;
}

.fc .fc-col-header-cell-cushion:first-letter {
  text-transform: uppercase;
}

.fc .fc-button:not(:disabled) {
  color: #422c76 !important;
  opacity: 1;
  font-weight: bold;
}

.fc .fc-button-primary:disabled {
  display: none;
}

.fc .fc-button .fc-icon {
  color: #422c76;
}

.fc .fc-daygrid-more-link {
  color: #422c76;
  text-decoration: underline;
  font-weight: bold;
}

.fc-daygrid-day-bottom {
  margin-top: 10px !important;
}

.fc .fc-popover-close {
  color: red;
}
